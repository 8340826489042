body,
html {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background: #fff;
}

a:active {
    opacity: 0.8;
}

.font-h {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.loading {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
}

img {
    display: block;
    width: 100%;
    height: 100%;
}

.index {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0 auto;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.index .header-position {
    position: fixed;
    top: -70px;
    width: 100%;
    height: 70px;
    background: rgba(9, 11, 15, 0.8);
    z-index: 5;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .header-position .content {
    width: 1531px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.index .header-position .content .logo {
    width: 135px;
    height: auto
}

.index .header-position .content .nav {
    width: 600px;
    height: 100%;
    margin-right: -22px;
}

.index .header-position .content .nav ul {
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.index .header-position .content .nav ul li {
    display: block;
    padding: 0 22px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.index .header-position .content .nav ul li a {
    display: block;
    color: #fff;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .header-position .content .nav ul li a:hover {
    color: #68cec2;
}

.index .header-position .navBox {
    position: absolute;
    right: 0px;
    top: 0;
    z-index: 996;
    width: 64px;
    cursor: pointer;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}

.index .header-position .nav_more {
    background: 0 0;
    display: none;
    padding: 20px 20px;
    cursor: pointer;
    z-index: 99999;
}

.index .header-position .nav_more .icon-bar {
    display: block;
    margin: 6px 0;
    width: 100%;
    height: 2px;
    background: #fff;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.index .header-position .change {
    background: #121d1f;
}

.index .header-position .change .top {
    -webkit-transform: translateY(10px) rotateZ(45deg);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.index .header-position .change .middle {
    width: 0;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.index .header-position .change .bottom {
    -webkit-transform: translateY(-6px) rotateZ(-45deg);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.index .header-ani {
    top: 0;
}

.index .content-01 {
    position: relative;
    width: 100%;
    height: 100vh;
}

.index .content-01 .nav {
    position: absolute;
    top: 0;
    width: 100%;
    min-width: 1200px;
    /*height: 44px;*/
    padding-top: 50px;
    z-index: 2;
}

.index .content-01 .nav .head-content {
    width: 100%;
    max-width: 1534px;
    height: 44px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.index .content-01 .nav .head-content .logo {
    width: 177px;
    height: 33px;
    margin: 10px 0 0 10px;
}

.index .content-01 .nav .head-content .logo img {
    display: block;
    width: 100%;
}

.index .content-01 .nav .head-content .nav-info {
    display: block;
}

.index .content-01 .nav .head-content .nav-info ul {
    width: 470px;
    padding-top: 13px;
    margin-right: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}

.index .content-01 .nav .head-content .nav-info ul li a {
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .content-01 .nav .head-content .nav-info ul li a:hover {
    color: #68cec2;
}

.index .content-01 .banner_wrap {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.index .content-01 .banner_wrap .banner1_btn {
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
    height: 100px;
    z-index: 2;
}

.index .content-01 .banner_wrap .banner1_btn .center {
    position: relative;
    width: 100%;
    height: 100px;
}

.index .content-01 .banner_wrap .banner1_btn .center:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(9, 11, 15, 0.6);
    content: '';
}

.index .content-01 .banner_wrap .banner1_btn ul {
    position: absolute;
    z-index: 2;
    left: 50%;
    width: 1543px;
    height: 100px;
    margin-left: -767px;
}

.index .content-01 .banner_wrap .banner1_btn ul li {
    display: flex;
    align-items: center;
    float: left;
    height: 100px;
    position: relative;
    cursor: pointer;
    color: #fff;
}

.index .content-01 .banner_wrap .banner1_btn ul li dt {
    display: block;
    padding-bottom: 6px;
    font-size: 12px;
    opacity: 0.6;
}

.index .content-01 .banner_wrap .banner1_btn ul li dd {
    display: block;
    font-weight: 500;
    font-size: 16px;
}

.index .content-01 .banner_wrap .banner1_btn ul li:nth-child(1) {
    width: 710px;
}

.index .content-01 .banner_wrap .banner1_btn ul li:nth-child(2) {
    width: 710px;
}

.index .content-01 .banner_wrap .banner1_btn ul li:nth-child(3) {
    width: 506px;
    padding-left: 0;
}

.index .content-01 .banner_wrap .banner1_btn ul li:after {
    position: absolute;
    top: -4px;
    left: 0;
    width: 0%;
    height: 3px;
    background: #fff;
    content: '';
    opacity: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.index .content-01 .banner_wrap .banner1_btn ul .active:after {
    width: 100%;
    opacity: 1;
}

.index .content-01 .banner_wrap .banner1_btn ul .active img {
    -webkit-filter: brightness(1);
}

.index .content-01 .banner_wrap .banner-info .bg {
    position: relative;
    left: 50%;
    width: 1920px;
    height: 100%;
    margin-left: -960px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.index .content-01 .banner_wrap .banner-info .bg img {
    display: block;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box {
    position: absolute;
    top: 23.5%;
    left: 50%;
    width: 780px;
    height: 288px;
    margin-left: -760px;
    color: #fff;
    z-index: 2;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box h1 {
    height: 146px;
    padding-bottom: 15px;
    margin-left: -3px;
    font-weight: bold;
    font-size: 76px;
    display: flex;
    align-items: center;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box .ts {
    font-size: 54px;
    line-height: 62px;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box .ts span {
    margin-top: -43px;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box p {
    padding-bottom: 107px;
    font-size: 16px;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box p span {
    opacity: 0.75;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box a {
    display: block;
    width: 225px;
    height: 51px;
    border-radius: 3px;
    background: #68cec2;
    color: #fff;
    font-size: 14px;
    line-height: 51px;
    text-align: center;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .content-01 .banner_wrap .banner-info .bg .info-box a:hover {
    background: #fff;
    color: #68cec2;
}

.index .content-01 .banner_wrap .banner-info .bg .pc_img {
    display: block;
}

.index .content-01 .banner_wrap .banner-info .bg .move_img {
    display: none;
}

.index .content-01 .banner_wrap .banner-info .bg:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(25, 29, 35, 0.55);
    content: '';
    z-index: 1;
}

.index .content-02 {
    position: relative;
    width: 100%;
    height: 580px;
    margin: 0 auto;
    padding-top: 174px;
    background: #e2edef;
}

.index .content-02 .center-content {
    max-width: 1534px;
    height: 406px;
    margin: 0 auto;
    background: #fff;
    display: flex;
    justify-content: space-between;
}

.index .content-02 .center-content .logo {
    width: 425px;
    height: 374px;
    margin: -48px 0 0 55px;
}

.index .content-02 .center-content .article {
    width: 878px;
    margin: -84px 55px 0 0;
}

.index .content-02 .center-content .article h2 {
    position: relative;
    color: #262626;
    font-size: 30px;
    text-align: center;
}

.index .content-02 .center-content .article h2 strong {
    font-weight: bold;
}

.index .content-02 .center-content .article h2 p {
    position: absolute;
    width: 83%;
    margin-left: 11%;
    height: 56px;
    line-height: 50px;
    z-index: 1;
    text-align-last: justify;
    font-weight: 400;
}

.index .content-02 .center-content .article h2:after {
    position: absolute;
    top: 4px;
    left: 50%;
    width: 407px;
    height: 94px;
    margin-left: -151px;
    background: #ffddd3;
    content: '';
}

.index .content-02 .center-content .article .info {
    position: relative;
    padding: 116px 0 0 48px;
}

.index .content-02 .center-content .article .info div {
    position: absolute;
    z-index: 1;
}

.index .content-02 .center-content .article .info p {
    margin-bottom: 1em;
    color: #353535;
    line-height: 1.5em;
    font-size: 18px;
    text-align: justify;
}

.index .content-02 .center-content .article .info:after {
    position: absolute;
    top: 282px;
    left: 0;
    width: 657px;
    height: 35px;
    background: #ffddd3;
    content: '';
}

.index .content-03 {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 384px;
    margin: 0 auto;
}

.index .content-03 .game-img {
    background: url('/images/bg_img.png') 50% 0 no-repeat fixed;
    height: 1364px;
    margin: 0 auto;
}

.index .content-03 .info {
    position: absolute;
    top: 0;
    left: 50%;
    width: 800px;
    height: 384px;
    margin-left: -392px;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.index .content-03 .info div {
    width: 100%;
}

.index .content-03 .info h2 {
    margin-left: 3px;
    font-size: 38px;
    font-weight: bold;
}

.index .content-03 .info p {
    width: 72%;
    height: 26px;
    margin: 0 auto;
    padding: 36px 0 30px;
    font-size: 18px;
    text-align-last: justify;
}

.index .content-03 .info a {
    display: block;
    width: 233px;
    height: 53px;
    margin: 0 auto;
    border-radius: 3px;
    background: #68cec2;
    color: #fff;
    font-size: 14px;
    line-height: 53px;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .content-03 .info a:hover {
    background: #fff;
    color: #68cec2;
}

.index .content-04 {
    position: relative;
    width: 100%;
    height: 736px;
    margin: 0 auto;
    background: #e2edef;
    display: flex;
    align-items: center;
}

.index .content-04 .center-content {
    width: 1530px;
    height: 510px;
    margin: 0 auto;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
}

.index .content-04 .center-content .info {
    width: 752px;
    height: 100%;
}

.index .content-04 .center-content .info h3 {
    width: 100%;
    height: 75px;
}

.index .content-04 .center-content .info h3 strong,
.index .content-04 .center-content .info h3 span {
    display: block;
    color: #262626;
}

.index .content-04 .center-content .info h3 strong {
    height: 38px;
    margin-left: -3px;
    font-size: 33px;
}

.index .content-04 .center-content .info h3 span {
    height: 27px;
    padding-top: 10px;
    font-size: 14px;
    opacity: 0.6;
}

.index .content-04 .center-content .info div {
    width: 100%;
    height: 420px;
}

.index .content-04 .center-content .info div a {
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .content-04 .center-content .info div a img {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .content-04 .center-content .info div a:hover {
    opacity: 0.9;
}

.index .content-04 .center-content .info div a:hover img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.index .content-05 {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-width: 1200px;
    height: 430px;
    margin: 0 auto;
}

.index .content-05 .gm-style .place-card-large {
    display: none!important;
}

.index .content-05 .map-box {
    position: relative;
    background: url('/images/map_img.png') 50% 0 no-repeat fixed;
    height: 700px;
    margin: 0 auto;
    z-index: 0;
}

.index .content-05 .google-map {
    position: absolute;
    top: -120px;
    left: 50%;
    display: block;
    width: 1920px;
    height: 650px;
    margin: 0;
    margin-left: -960px;
    padding: 0;
    border: 0;
    z-index: 1;
}

.index .content-05 .location {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 700px;
    background: url('/images/dot_x.png') 50% 0 no-repeat fixed;
}

.index .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 575px;
    padding-top: 86px;
    margin: 0 auto;
    background: #fff;
}

.index .footer .center-content {
    max-width: 1534px;
    margin: 0 auto;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
}

.index .footer .center-content .info-box {
    width: auto;
    padding-right: 130px;
    padding-top: 36px;
}

.index .footer .center-content .info-box .info-list {
    display: flex;
    justify-content: space-between;
}

.index .footer .center-content .info-box .info-list ul {
    width: 258px;
    padding-left: 0;
}

.index .footer .center-content .info-box .info-list ul h4 {
    color: #262626;
    font-size: 12px;
    font-weight: bold;
}

.index .footer .center-content .info-box .info-list ul li {
    height: 36px;
    font-size: 12px;
    line-height: 36px;
}

.index .footer .center-content .info-box .info-list ul li a {
    color: #262626;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    opacity: 0.8;
}

.index .footer .center-content .info-box .info-list ul li a:hover {
    color: #68cec2;
    opacity: 1;
}

.index .footer .center-content .info-box .share-list {
    height: 70px;
    padding-top: 36px;
}

.index .footer .center-content .info-box .share-list ul {
    display: flex;
    padding-left: 0;
}

.index .footer .center-content .info-box .share-list li {
    width: 68px;
    height: 68px;
    border: 1px solid #bbb;
    border-radius: 50px;
    margin-right: 20px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.index .footer .center-content .info-box .share-list li a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.index .footer .center-content .info-box .share-list li a i {
    display: block;
    width: 30px;
    height: 30px;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    opacity: 0.4;
}

.index .footer .center-content .info-box .share-list li a .s-icon-1 {
    background: url("/images/icon_01.png");
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -ms-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

.index .footer .center-content .info-box .share-list li a .s-icon-2 {
    background: url("/images/icon_02.png");
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -ms-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

.index .footer .center-content .info-box .share-list li:hover {
    border: 1px solid #303947;
}

.index .footer .center-content .info-box .share-list li:hover a i {
    opacity: 1;
}

.index .footer .center-content .address {
    width: 618px;
    height: 430px;
    background: #87CEC5;
    /*  background: url("/images/f_logo_bg.jpg");
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.index .footer .center-content .address .logo {
    display: block;
    width: 102px;
    height: 96px;
    margin: 0 auto 60px;
}

.index .footer .center-content .address .add-info {
    color: #fff;
    line-height: 28px;
    font-size: 14px;
    text-align: center;
}

.index .footer .center-content .address .add-info span {
    display: inline-block;
}

.index .footer .center-content .address .add-info p:nth-child(2),
.index .footer .center-content .address .add-info p:nth-child(3) {
    font-size: 13px;
}

.index-test {
    position: absolute;
    top: -154px;
    width: 1920px;
    height: 4069px;
    z-index: 999;
    opacity: 0.2;
}

.index-test img {
    display: block;
    width: 100%;
}

.index .content-01 .nav-black {
    padding-top: 25px;
    padding-bottom: 30px;
    background: rgba(9, 11, 15, .8);
}

.index .a-page-header {
    height: 560px;
}

.index .a-page-header .a-page-header-warp {
    /*background: url('/images/banner_aboutUs.png') 50% 0 no-repeat fixed;*/
    height: 560px;
    margin: 0 auto;
}

.index .a-page-header .about-us-background-image {
    background: url('/images/banner_about_us.png') 50% 0 no-repeat fixed;
}

.index .a-page-header .game-background-image {
    background: url('/images/banner_games.png') 50% 0 no-repeat fixed;
}

.index .a-page-header .news-background-image {
    background: url('/images/banner_news.png') 50% 0 no-repeat fixed;
}

.index .a-page-header .contact-us-background-image {
    background: url('/images/banner_cooperation.png') 50% 0 no-repeat fixed;
}

.index .a-page-header .a-page-header-inner {
    width: 88%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.index .a-page-header .a-page-header-inner h1 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 25px;
    text-align: center;
    margin-top: 85px;
}

.index .a-page-header .a-page-header-inner p {
    color: #fff;
    font-size: 24px;
    text-align: center;
}

.index .a-page-header .a-page-header-inner .new-detail-title {
    line-height: 52px;
    font-weight: inherit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.index .a-page-header .a-page-header-inner .new-detail-date {
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.index .a-form-wrap,
.index .news-wrap,
.index .game-wrap,
.index .product-wrap {
    padding: 75px 0;
    background-color: #e2edef;
}

.index .a-form-wrap .a-form-inner,
.index .news-wrap .news-inner,
.index .game-wrap .game-inner,
.index .product-wrap .product-inner {
    width: 90%;
    margin: 0 auto;
    max-width: 1520px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 40px 80px;
}

.index .product-wrap .product-inner {
    background-color: #e2edef;
    padding-top: 0;
}

.index .a-form-wrap .a-form-inner .a-form-prompt {
    font-size: 14px;
    line-height: 30px;
    padding-bottom: 30px;
}

.index .a-form-wrap .a-form-inner .a-form-prompt p {
    margin: 12px 0;
}


/* .index .a-form-wrap .a-form-inner .a-form-content {
  border: 1px solid #ccc;
} */

.index .a-form-wrap .a-form-inner .a-form-content .form-row {
    display: flex;
    justify-content: space-between;
    padding: 27px 0;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item {
    flex: 1;
    padding: 15px 0px;
    position: relative;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .white-space {
    width: 6%;
    height: 10px;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item h3 {
    position: relative;
    font-size: 20px;
    color: #262626;
    font-weight: 600;
    padding-bottom: 10px;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item h3 div {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 22px;
    line-height: 22px;
    color: #fff;
    background-color: #e49479;
    font-size: 12px;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0 8px;
    text-align: right;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item h3 div span {
    font-weight: 600;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item p {
    font-size: 14px;
    color: #a7a7a7;
    padding-bottom: 15px;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item input,
.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item textarea {
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    background-color: #ececec;
    padding: 22px;
    color: #828282;
    font-weight: 600;
    border: 1px solid #fff;
    outline: none;
    -webkit-appearance: none;
    /*去除系统默认的样式*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* 点击高亮的颜色*/
    resize: none;
}


/* WebKit browsers */

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item input::-webkit-input-placeholder,
.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item textarea::-webkit-input-placeholder {
    color: #828282;
    font-weight: 600;
}


/* Mozilla Firefox 4 to 18 */

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item input:-moz-placeholder,
.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item textarea:-moz-placeholder {
    color: #828282;
    opacity: 1;
    font-weight: 600;
}


/* Mozilla Firefox 19+ */

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item input::-moz-placeholder,
.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item textarea::-moz-placeholder {
    color: #828282;
    opacity: 1;
    font-weight: 600;
}


/* Internet Explorer 10+ */

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item input:-ms-input-placeholder .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item textarea:-ms-input-placeholder {
    color: #828282;
    font-weight: 600;
}

.index .a-form-wrap .a-form-inner .contact_us_submit {
    display: block;
    width: 233px;
    height: 50px;
    margin: 27px auto 70px auto;
    border-radius: 3px;
    background: #68cec2;
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 50px;
    transition: all .25s;
}

.index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item .error-message {
    position: absolute;
    top: 100%;
    left: 0px;
    color: red;
    font-size: 14px;
}


/* news */

.index .news-wrap .news-inner {
    padding: 5px 5% 60px 5%;
    /* padding: 5px 106px 60px 106px; */
}

.index .news-wrap .news-inner dl {
    font-size: 0;
}

.index .news-wrap .news-inner dl dt {
    border-bottom: 1px dashed #c8c8c8;
    text-align: right;
    font-size: 24px;
    color: #b8b8b8;
    margin: 0 0 30px 0;
    padding-top: 30px;
}

.index .news-wrap .news-inner dl dd {
    padding: 20px 0;
    display: inline-flex;
}

.index .news-wrap .news-inner dl .new-item {
    width: 47%;
    font-size: 16px;
    height: 112px;
    justify-content: space-between;
    overflow: hidden;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .news-wrap .news-inner dl .new-item:hover {
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    transform: translateY(-3px);
}

.index .news-wrap .news-inner dl .new-item ul {
    width: 85px;
    height: 100%;
    box-sizing: border-box;
    padding: 12px 22px;
    background-color: #97c1d7;
    margin-right: 30px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.index .news-wrap .news-inner dl .new-item ul li {
    width: 74px;
    color: #fff;
    text-align: center;
    font-weight: 400;
}

.index .news-wrap .news-inner dl .new-item ul li:nth-child(1) {
    font-size: 22px;
    line-height: 22px;
}

.index .news-wrap .news-inner dl .new-item ul li:nth-child(2) {
    font-size: 45px;
    height: 45px;
    line-height: 45px;
}

.index .news-wrap .news-inner dl .new-item ul li:nth-child(3) {
    font-size: 22px;
    line-height: 22px;
}

.index .news-wrap .news-inner dl .new-item a {
    flex: 1;
    width: calc(100% - 85px - 30px);
    display: block;
    color: #000;
}

.index .news-wrap .news-inner dl .new-item a h4 {
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 10px;
}

.index .news-wrap .news-inner dl .new-item a p {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.index .news-wrap .news-inner dl .white-space {
    width: 6%;
    height: 10px;
}

.index .game-wrap .game-inner .game-prompt {
    padding-bottom: 40px;
}

.index .game-wrap .game-inner .game-prompt h1 {
    font-size: 28px;
    text-align: center;
    padding-bottom: 30px;
}

.index .game-wrap .game-inner .game-prompt p {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    padding-bottom: 10px;
}

.index .game-wrap .game-inner .game-prompt img {
    width: auto;
}

.index .game-wrap .game-inner .box_table_wide {
    padding-bottom: 10px;
}

.index .game-wrap .game-inner .box_table_wide table {
    border-bottom: 1px solid #ddd;
    margin: 0 auto;
}

.index .game-wrap .game-inner .box_table_wide th {
    box-sizing: border-box;
    width: 300px;
    min-width: 200px;
    vertical-align: middle;
    background: #fafafa;
    font-weight: normal;
    border: 1px solid #ddd;
    padding: 10px 15px 7px;
    line-height: 2.2;
    font-size: 14px;
    text-align: center;
}

.index .game-wrap .game-inner .box_table_wide td {
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px 15px 7px;
    line-height: 2.2;
    background: #fff;
    font-size: 14px;
}

.index .product-wrap .product-inner>ul {
    font-size: 0;
}

.index .product-wrap .product-inner>ul li {
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    display: inline-block;
    width: 32%;
    background-color: #fff;
    margin-bottom: 36px;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .product-wrap .product-inner>ul li .img-box {
    width: 100%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    position: relative;
}

.index .product-wrap .product-inner>ul li .img-box .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(18, 29, 31, 0.3);
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .product-wrap .product-inner>ul li .img-box img {
    object-fit: cover;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
}

.index .product-wrap .product-inner>ul .middle {
    margin: 0 2%;
}

.index .product-wrap .product-inner>ul li .product-info {
    padding: 16px 24px 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.index .product-wrap .product-inner>ul li .product-info div:first-child {
    width: calc(100% - 40px);
}

.index .product-wrap .product-inner>ul li .product-info h3 {
    font-size: 18px;
    font-weight: 600;
    color: #262626;
    padding-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.index .product-wrap .product-inner>ul li .product-info p {
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    color: #010101;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.index .product-wrap .product-inner>ul li .product-info .product-more {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 1px solid #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.index .product-wrap .product-inner>ul li .product-info .product-more span {
    border: 1px solid #d2d2d2;
    width: 12px;
    height: 12px;
    display: block;
    border-left: 0;
    border-bottom: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    margin-left: -6px;
}

.index .product-wrap .product-inner>ul li:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.index .product-wrap .product-inner>ul li:hover {
    box-shadow: 3px 3px 10px #888888;
    -moz-box-shadow: 3px 3px 10px #888888;
    -webkit-box-shadow: 3px 3px 10px #888888;
}

.index .product-wrap .product-inner>ul li:hover .mask {
    background-color: rgba(18, 29, 31, 0);
}

.index .bread-crumbs ul {
    margin-bottom: 30px;
}

.index .bread-crumbs ul li {
    font-size: 14px;
    padding-right: 25px;
    position: relative;
    display: inline;
}

.index .product-wrap .product-inner .bread-crumbs ul li {
    width: auto;
    background-color: transparent;
}

.index .bread-crumbs ul li:first-child::after {
    display: none;
}

.index .bread-crumbs ul li::after {
    content: "";
    position: absolute;
    display: block;
    top: 5px;
    left: -18px;
    width: 6px;
    height: 6px;
    border: 1px solid #B7B7B7;
    border-left: 0;
    border-bottom: 0;
    transform: rotate(45deg);
}

.index .bread-crumbs ul li a,
.index .bread-crumbs ul li span {
    color: #000;
}

.index .bread-crumbs ul li:first-child a {
    display: inline-block;
    width: 20px;
    height: 16px;
    position: relative;
    top: 4px;
}

.index .bread-crumbs ul li a:hover {
    color: #E37100;
}

.index .bread-crumbs .home {
    width: 16px;
    margin-top: -1px;
}

.index .bread-crumbs .home .home-orange {
    display: none;
}

.index .bread-crumbs .home:hover .home-black {
    display: none;
}

.index .bread-crumbs .home:hover .home-orange {
    display: inherit;
}

.index .news-inner .bread-crumbs {
    margin-top: 40px;
}

.index .news-inner .bread-crumbs ul {
    margin-bottom: 0px;
}

.index .product-wrap .product-inner {
    padding: 40px 0 10px 0;
}

.custom-layer .layui-layer-btn .layui-layer-btn0 {
    border-color: #68cec2;
    background-color: #68cec2;
}


/* .custom-layer .layui-layer-btn .layui-layer-btn0:hover {

} */

@media screen and (min-width: 1280px) and (max-width: 1680px) {
    .index .header-position .content {
        width: 1300px;
    }
    .index .header-position .content .logo {
        margin-left: 34px;
    }
    .index .header-position .content .nav {
        margin-right: 15px;
    }
    .index .content-01 .nav {
        padding-top: 25px;
    }
    .index .content-01 .nav .head-content {
        width: 1220px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box {
        top: 30%;
        margin-left: -600px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box h1 {
        padding-bottom: 0;
        height: 125px;
        margin-left: -3px;
        font-weight: bold;
        font-size: 74px;
        display: flex;
        align-items: center;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box .ts {
        font-size: 54px;
        line-height: 62px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box .ts span {
        margin-top: -43px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box p {
        padding-bottom: 55px;
        font-size: 16px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box p span {
        opacity: 0.75;
    }
    .index .content-01 .banner_wrap .banner1_btn {
        height: 70px;
    }
    .index .content-01 .banner_wrap .banner1_btn .center {
        height: 100%;
    }
    .index .content-01 .banner_wrap .banner1_btn ul {
        width: 1225px;
        height: 100%;
        margin-left: -613px;
    }
    .index .content-01 .banner_wrap .banner1_btn ul li {
        height: 100%;
    }
    .index .content-01 .banner_wrap .banner1_btn ul li:nth-child(1),
    .index .content-01 .banner_wrap .banner1_btn ul li:nth-child(2),
    .index .content-01 .banner_wrap .banner1_btn ul li:nth-child(3) {
        width: 600px;
        padding-left: 0;
    }
    .index .content-02 {
        height: 423px;
    }
    .index .content-02 .center-content {
        max-width: 1227px;
        height: 320px;
    }
    .index .content-02 .center-content .logo {
        width: 340px;
        height: 299px;
        margin: -26px 0 0 38px;
    }
    .index .content-02 .center-content .article {
        margin: -84px 36px 0 0;
    }
    .index .content-02 .center-content .article h2 p {
        margin-left: 17%;
    }
    .index .content-02 .center-content .article h2:after {
        top: 4px;
        height: 88px;
        width: 412px;
    }
    .index .content-02 .center-content .article .info {
        padding: 118px 0 0 48px;
    }
    .index .content-02 .center-content .article .info div {
        margin-left: 3%;
    }
    .index .content-02 .center-content .article .info p {
        font-size: 15px;
        line-height: 1.5em;
    }
    .index .content-02 .center-content .article .info:after {
        top: 232px;
        left: 42px;
        width: 543px;
        height: 28px;
    }
    .index .content-03 .info p {
        width: 66%;
        padding: 31px 0 35px;
        font-size: 16px;
    }
    .index .content-04 {
        height: 600px;
    }
    .index .content-04 .center-content {
        width: 1228px;
        height: auto;
        padding-left: 0;
        margin-top: -20px;
    }
    .index .content-04 .center-content .info {
        width: 600px;
    }
    .index .content-04 .center-content .info h3 span {
        padding-top: 10px;
        opacity: 0.6;
        font-size: 12px;
    }
    .index .content-04 .center-content .info div {
        height: 336px;
    }
    .index .content-05 {
        height: 380px;
    }
    .index .footer {
        height: 480px;
        padding-top: 104px;
    }
    .index .footer .center-content {
        width: 1228px;
        padding-left: 0;
    }
    .index .footer .center-content .info-box {
        margin-top: -10px;
        padding-top: 0;
        padding-right: 22px;
    }
    .index .footer .center-content .info-box .info-list ul {
        width: 200px;
    }
    .index .footer .center-content .info-box .share-list ul li {
        width: 54px;
        height: 54px;
    }
    .index .footer .center-content .address {
        width: 531px;
        height: 370px;
    }
    .index .a-page-header {
        height: 420px;
        min-height: auto;
    }
    .index .a-page-header .a-page-header-warp {
        height: 420px;
        /* background-position: 50% -170px; */
    }
    .index .product-wrap {
        padding: 40px 0 10px 0;
    }
    .index .product-wrap .product-inner {
        padding: 0 6px 0 8px;
        width: 1220px;
    }
    .index .news-wrap .news-inner dl dt {
        margin: 0 0 20px 0;
    }
    .index .game-wrap .game-inner .new-content {
        padding-bottom: 25px;
    }
    .index .news-wrap .news-inner,
    .index .game-wrap .game-inner,
    .index .a-form-wrap .a-form-inner {
        width: 1208px;
    }
    .index .news-wrap .news-inner {
        padding: 5px 50px 60px 50px;
    }
    .sub-page .header-position .content {
        width: 1274px;
    }
    .sub-page .footer .center-content {
        width: 1204px;
    }
}

@media screen and (min-width: 0px) and (max-width: 1280px) {
    /* .index {
    margin-top: 70px;
  } */
    .index .header-position {
        top: 0;
    }
    .index .header-position .content {
        width: auto;
        /* background: rgba(67, 80, 88, 0.6); */
        background: rgba(9, 11, 15, .2);
    }
    .index .header-position .content .logo {
        padding-left: 24px;
    }
    .index .header-position .content .nav {
        display: none;
        position: absolute;
        top: -100vh;
        width: 100%;
        height: 100vh;
        background: rgba(18, 29, 31, 0.9);
        z-index: -1;
    }
    .index .header-position .content .nav ul {
        display: block;
        height: 100%;
        height: auto;
    }
    .index .header-position .content .nav ul li {
        height: 80px;
        line-height: 80px;
        font-size: 20px;
    }
    .index .header-position .nav_more {
        display: block;
        margin-right: 2px;
    }
    .index .content-01 {
        height: 850px;
        min-height: 445px;
    }
    .index .content-01 .nav {
        display: none;
    }
    .index .content-01 .banner_wrap .banner-info {
        height: 850px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box {
        top: 30%;
        width: 100vw;
        margin-left: 80px;
        transform: translateX(-50%);
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box h1 {
        font-size: 40px;
        height: auto;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box .ts {
        font-size: 40px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box p {
        font-size: 18px;
        line-height: 35px;
        margin-bottom: 20px;
        padding-bottom: 0;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box a {
        font-size: 14px;
        line-height: 50px;
        width: 220px;
        padding: 0 15px;
        height: 50px;
    }
    .index .content-01 .banner_wrap .banner1_btn {
        display: none;
    }
    .index .content-02 {
        height: auto;
        padding: 50px 0 50px 0;
    }
    .index .content-02 .center-content {
        max-width: 1227px;
        height: auto;
    }
    .index .content-02 .center-content .logo {
        display: none;
    }
    .index .content-02 .center-content .article {
        width: 100%;
        margin: 0;
        padding: 25px 0 10px;
    }
    .index .content-02 .center-content .article h2 {
        font-size: 40px;
        height: auto;
        /* transform: translateY(-28px); */
        margin: 0 auto;
        max-width: 950px;
        margin-bottom: 1em;
    }
    .index .content-02 .center-content .article h2 p {
        /* margin-left: 3%;
    position: absolute;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
        width: 94%;
        height: auto;
        line-height: inherit;
        text-align-last: center;
        position: static;
        margin: 0 auto;
    }
    .index .content-02 .center-content .article h2 strong {
        display: block;
    }
    .index .content-02 .center-content .article h2:after {
        /* width: 94%;
    left: 0;
    top: 2px;
    height: 46px;
    margin-left: 3%; */
        display: none;
    }
    .index .content-02 .center-content .article .info p {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 1.5em;
        text-indent: 2em;
    }
    .index .content-02 .center-content .article .info {
        padding: 0;
    }
    .index .content-02 .center-content .article .info div {
        position: relative;
        width: 84%;
        margin: 0 auto;
        max-width: 750px;
    }
    .index .content-02 .center-content .article .info:after {
        /* width: 76%;
    top: 158px;
    height: 25px;
    max-width: 600px; */
        display: none;
    }
    .index .content-03 {
        height: 350px;
    }
    .index .content-03 .info {
        height: 350px;
        width: 100%;
        left: 0;
        margin-left: 0;
    }
    .index .content-03 .info h2 {
        font-size: 40px;
        line-height: 1.4;
        padding-bottom: 15px;
    }
    .index .content-03 .info p {
        font-size: 16px;
        line-height: 2.2;
        margin: 0 auto 14px auto;
        padding: 0;
        text-align-last: center;
        height: auto;
        width: 94%;
    }
    .index .content-03 .info a {
        font-size: 14px;
        line-height: 50px;
        min-width: 220px;
        height: 50px;
        padding: 0 15px;
    }
    .index .content-03 .game-img {
        background-size: cover;
        background-position: 50% 0 !important;
    }
    .index .content-04 {
        height: auto;
        padding: 40px 0;
    }
    .index .content-04 .center-content {
        width: 94%;
        height: auto;
    }
    .index .content-04 .center-content .info {
        width: 48%;
        margin: 0 auto;
    }
    .index .content-04 .center-content .info div {
        height: auto;
        padding-bottom: 10px;
    }
    .index .content-04 .center-content .info h3 {
        height: auto;
    }
    .index .content-04 .center-content .info h3 strong {
        font-size: 28px;
        text-align: center;
    }
    .index .content-04 .center-content .info h3 span {
        text-align: center;
        padding: 6px 0 0px 0;
    }
    .index .content-05 .map-box {
        background-size: cover;
        background-position: 50% 0 !important;
    }
    .index .content-05 .google-map {
        top: 0 !important;
    }
    /* .index .footer {
    height: 480px;
  }
  .index .footer .center-content {
    width: 1000px;
  }
  .index .footer .center-content .info-box {
    padding-top: 5px;
    padding-right: 22px;
  }
  .index .footer .center-content .info-box .info-list ul {
    width: 180px;
  }
  .index .footer .center-content .info-box .share-list {
    padding-top: 20px;
  }
  .index .footer .center-content .info-box .share-list ul li {
    width: 54px;
    height: 54px;
  }
  .index .footer .center-content .address {
    width: 444px;
    height: 309px;
  } */
    .index .footer {
        height: auto;
        padding-top: 0;
    }
    .index .footer .center-content {
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        flex-direction: column;
    }
    .index .footer .center-content .info-box {
        padding: 36px 40px 37px 40px;
    }
    .index .footer .center-content .info-box .info-list ul {
        width: auto;
    }
    .index .footer .center-content .info-box .share-list {
        padding-top: 20px;
    }
    .index .footer .center-content .info-box .share-list ul li {
        width: 54px;
        height: 54px;
    }
    .index .footer .center-content .address {
        width: 100%;
        height: 35vw;
        min-height: 225px;
        max-height: 355px;
        background-size: cover;
    }
    .index .footer .center-content .address .logo {
        margin: 15px auto;
        width: 70px;
        height: 65px;
    }
    .index .footer .center-content .address .add-info p:nth-child(2) {
        font-size: 12px;
    }
    .index .a-page-header {
        height: 420px;
        min-height: auto;
    }
    .index .a-page-header .a-page-header-warp {
        height: 420px;
    }
    .index .a-page-header .a-page-header-inner h1 {
        margin-top: 60px;
    }
    .index .a-form-wrap,
    .index .news-wrap {
        padding: 50px 0;
    }
    .index .news-wrap .news-inner {
        width: auto;
        margin: 0px 50px 0px 50px;
        padding: 5px 30px 40px 30px;
    }
    .index .news-wrap .news-inner dl dt {
        padding-top: 20px;
        margin: 0 0 15px 0;
    }
    .index .news-wrap .news-inner dl dd {
        padding: 15px 0;
    }
    .index .news-wrap .news-inner dl .new-item {
        width: 48%;
    }
    .index .news-wrap .news-inner dl .white-space {
        width: 4%;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .white-space {
        width: 2%;
    }
    .index .game-wrap .game-inner .game-prompt {
        padding-bottom: 40px;
    }
    .index .game-wrap .game-inner .game-prompt p {
        text-indent: 2em;
        padding-bottom: 10px;
    }
    .index .game-wrap .game-inner .box_table_wide th {
        width: 270px;
    }
    .index .a-form-wrap .a-form-inner .a-form-prompt p {
        text-indent: 2em;
    }
    .index .content-05 {
        min-width: auto;
    }
    .index .content-05 .map-box {
        background: url(/images/map_img.png) 50% 0 no-repeat;
    }
    .index .content-05 .location {
        width: 205px;
        height: 350px;
        left: 50%;
        top: 50%;
        margin-left: -102px;
        margin-top: -251px;
        background: url(/images/dot_x.png) 50% 0 no-repeat;
    }
    .index .product-wrap {
        padding: 20px 0 30px 0;
    }
    .index .product-wrap .product-inner {
        width: auto;
        padding: 10px 50px;
    }
    .index .product-wrap .product-inner>ul li .product-info .product-more {
        width: 30px;
        height: 30px;
    }
    .index .product-wrap .product-inner>ul li .product-info .product-more span {
        width: 8px;
        height: 8px;
        margin-left: -4px;
    }
    .index .product-wrap .product-inner>ul li {
        margin-bottom: 20px;
    }
    .index .product-wrap .product-inner {
        width: auto;
        padding: 30px 50px;
    }
    .index .product-wrap .product-inner>ul li .product-info {
        padding: 10px 20px 14px 20px;
    }
    .index .product-wrap .product-inner>ul li .product-info h3 {
        font-size: 18px;
        padding-bottom: 6px;
    }
    .index .product-wrap .product-inner>ul li .product-info p {
        font-size: 12px;
    }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
    .index .news-wrap .news-inner dl .new-item {
        display: flex;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
    .index .news-wrap .news-inner dl .white-space {
        display: none;
    }
    .index .a-form-wrap .a-form-inner .a-form-prompt {
        padding-bottom: 20px;
    }
    .index .a-form-wrap .a-form-inner {
        padding: 30px 40px;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row {
        display: block;
        padding: 0;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item {
        margin: 30px 0;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .white-space {
        display: none;
    }
    .index .game-wrap {
        padding: 40px 0;
    }
    .index .game-wrap .game-inner {
        padding: 30px 40px;
    }
    .index .game-wrap .game-inner .game-prompt {
        padding-bottom: 40px;
    }
    .index .game-wrap .game-inner .game-prompt h1 {
        padding-bottom: 20px;
        font-size: 24px;
    }
    .index .game-wrap .game-inner .game-prompt p {
        font-size: 14px;
    }
    .index .game-wrap .game-inner .box_table_wide th {
        width: 220px;
    }
    .index .product-wrap {
        padding: 40px 0 20px 0;
    }
    .index .product-wrap .product-inner {
        padding: 0px 20px;
    }
    .index .product-wrap .product-inner>ul li {
        width: 46%;
        margin: 0px 2% 25px 2%;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .index .content-01 {
        height: 780px;
    }
    .index .content-01 .banner_wrap .banner-info {
        height: 780px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box {
        top: 26%;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box h1 {
        font-size: 30px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box .ts {
        font-size: 30px;
        line-height: 42px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box p {
        font-size: 16px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box a {
        font-size: 14px;
        line-height: 40px;
        width: 150px;
        height: 40px;
    }
    .index .content-02 {
        padding: 40px 0 40px 0;
    }
    .index .content-02 .center-content .article h2 {
        font-size: 18px;
        height: auto;
        /* transform: translateY(-28px); */
        margin: 0 auto;
        max-width: 750px;
        margin-bottom: 1em;
    }
    .index .content-02 .center-content .article .info p {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 1em;
        text-indent: 2em;
    }
    .index .content-02 .center-content .article .info:after {
        top: 144px;
    }
    .index .content-03 {
        height: 300px;
    }
    .index .content-03 .info {
        height: 300px;
    }
    .index .content-03 .info h2 {
        font-size: 24px;
        padding-bottom: 10px;
    }
    .index .content-03 .info p {
        font-size: 12px;
        line-height: 2;
    }
    .index .content-03 .info a {
        font-size: 12px;
        line-height: 40px;
        width: 150px;
        height: 40px;
        min-width: auto;
    }
    .index .content-04 {
        padding: 0
    }
    .index .content-04 .center-content {
        width: 100%;
        height: auto;
        flex-direction: column;
        padding: 12px 0 20px 0;
    }
    .index .content-04 .center-content .info {
        width: 88%;
        padding: 20px 0 20px 0;
    }
    .index .content-04 .center-content .info div {
        height: auto;
        padding-bottom: 0px;
    }
    .index .content-04 .center-content .info h3 {
        text-align: center;
        font-size: 28px;
    }
    .index .content-04 .center-content .info h3 strong {
        font-size: 26px;
        margin-left: 0;
        height: auto;
    }
    .index .content-04 .center-content .info h3 span {
        padding-top: 6px;
    }
    .index .footer .center-content .info-box {
        padding: 26px 40px 25px 40px;
    }
    .index .a-page-header {
        height: 420px;
        min-height: auto;
    }
    .index .a-page-header .a-page-header-warp {
        height: 420px;
    }
    .index .a-form-wrap,
    .index .news-wrap {
        padding: 30px 0;
    }
    .index .news-wrap .news-inner {
        margin: 0 20px 0 20px;
        padding: 5px 20px 30px 20px;
    }
    .index .news-wrap .news-inner dl dt {
        font-size: 20px;
        padding-top: 12px;
        margin: 0 0 10px 0;
        line-height: 26px;
    }
    .index .news-wrap .news-inner dl dd {
        padding: 12px 0;
    }
    .index .news-wrap .news-inner dl .new-item {
        height: 110px;
    }
    .index .news-wrap .news-inner dl .new-item ul {
        padding: 8px 15px;
    }
    .index .news-wrap .news-inner dl .new-item a h4 {
        height: auto;
    }
    .index .a-form-wrap .a-form-inner {
        padding: 20px 30px;
    }
    .index .a-form-wrap .a-form-inner .a-form-prompt {
        line-height: 30px;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item {
        margin: 20px 0;
    }
    .index .a-page-header .a-page-header-inner h1 {
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 64px;
    }
    .index .a-page-header .a-page-header-inner p {
        font-size: 20px;
    }
    .index .game-wrap {
        padding: 30px 0;
    }
    .index .game-wrap .game-inner {
        padding: 30px 40px;
    }
    .index .game-wrap .game-inner .game-prompt {
        padding-bottom: 30px;
    }
    .index .game-wrap .game-inner .game-prompt h1 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 10px;
    }
    .index .game-wrap .game-inner .game-prompt p {
        /* font-size: 12px;
    line-height: 24px; */
        text-indent: 2em;
        padding-bottom: 6px;
    }
    .index .game-wrap .game-inner .game-prompt img {
        width: 100%;
    }
    .index .game-wrap .game-inner .box_table_wide th,
    .index .game-wrap .game-inner .box_table_wide td {
        width: auto;
        font-size: 12px;
        display: block;
        border-bottom: none;
    }
    .index .game-wrap .game-inner .box_table_wide th {
        width: auto;
    }
    .pc_img_01 {
        position: relative;
        top: -210px;
        transform: scale(0.85);
        -webkit-transform: scale(0.85);
    }
    .pc_img_02 {
        margin-left: -300px;
        position: relative;
        top: -100px;
        transform: scale(0.85);
        -webkit-transform: scale(0.85);
    }
    .pc_img_03 {
        position: relative;
        top: -150px;
        margin-left: -150px;
        transform: scale(0.75);
        -webkit-transform: scale(0.75);
    }
    .index .product-wrap {
        padding: 20px 0 0 0;
    }
    .index .product-wrap .product-inner {
        padding: 0 6px;
    }
    .index .product-wrap .product-inner>ul li .product-info {
        padding: 6px 10px 8px 10px;
    }
    .index .product-wrap .product-inner>ul li .product-info h3 {
        font-size: 16px;
        padding-bottom: 4px;
    }
    .index .product-wrap .product-inner>ul li .product-info div:first-child {
        width: calc(100% - 30px);
    }
    .index .product-wrap .product-inner>ul li .product-info .product-more {
        width: 25px;
        height: 25px;
    }
    .index .product-wrap .product-inner>ul li .product-info .product-more span {
        width: 6px;
        height: 6px;
        margin-left: -3px;
    }
    .index .news-inner .bread-crumbs {
        margin-top: 30px;
    }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
    .index .content-01 {
        height: 650px;
    }
    .index .content-01 .banner_wrap .banner-info {
        height: 650px;
    }
    .index .content-01 .banner_wrap .banner-info .bg {
        height: auto;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box {
        top: 32%;
        position: absolute;
        margin: 0;
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box h1 {
        padding-bottom: 0;
        font-size: 30px;
        text-align: center;
        display: block;
        height: auto;
        padding-bottom: 12px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box .ts {
        font-size: 26px;
        line-height: 32px;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box p {
        /* padding-bottom: 20px; */
        font-size: 14px;
        text-align: center;
    }
    .index .content-01 .banner_wrap .banner-info .bg .info-box a {
        font-size: 14px;
        line-height: 40px;
        width: 150px;
        height: 40px;
    }
    .index .content-02 {
        height: auto;
        padding: 30px 0 30px 0;
    }
    .index .content-02 .center-content .article h2 {
        font-size: 18px;
        height: auto;
        margin: 0 auto;
        max-width: 750px;
        margin-bottom: 16px;
    }
    .index .content-03 .info p {
        font-size: 12px;
        line-height: 1.8;
    }
    .index .content-03 .info p span {
        display: block;
    }
    .index .content-03 .game-img {
        background: url(/images/bg_img.png) 50% 0 no-repeat;
        background-size: auto 45%;
        background-position: 75% -46px!important;
    }
    .index .content-04 {
        padding: 0;
    }
    .index .content-04 .center-content {
        padding: 12px 0 17px 0;
    }
    .index .content-04 .center-content .info {
        width: 88%;
        padding: 12px 0 12px 0;
        margin: 0 auto;
    }
    .index .content-04 .center-content .info h3 {
        height: auto;
    }
    .index .content-04 .center-content .info h3 span {
        padding: 0px 0 0px 0;
        font-size: 12px;
        height: auto;
        padding-bottom: 10px;
    }
    .index .footer {
        padding: 0;
    }
    .index .footer .center-content .info-box {
        padding: 16px 20px 24px 20px;
    }
    .index .footer .center-content .info-box .share-list {
        height: auto;
    }
    .index .footer .center-content .info-box .share-list {
        padding-top: 12px;
    }
    .index .footer .center-content .info-box .share-list ul li {
        width: 45px;
        height: 45px;
    }
    .index .footer .center-content .info-box .share-list ul li i {
        transform: scale(0.8);
    }
    .index .a-page-header {
        height: 240px;
    }
    .index .a-page-header .a-page-header-warp {
        height: 240px;
    }
    .index .a-form-wrap,
    .index .news-wrap {
        padding: 20px 0;
    }
    .index .a-page-header .game-background-image {
        background-position: 50% -40px;
    }
    .index .a-page-header .news-background-image {
        background-position: 50% -65px;
    }
    .index .a-page-header .contact-us-background-image {
        background-size: 270% auto;
    }
    .index .a-page-header .about-us-background-image {
        background-size: 270% auto;
    }
    .index .news-wrap .news-inner {
        margin: 0 15px 0 15px;
        padding: 5px 15px 20px 15px;
    }
    .index .news-wrap .news-inner dl dt {
        font-size: 18px;
        padding-top: 10px;
        margin: 0 0 10px 0;
        line-height: 24px;
        padding-bottom: 4px;
    }
    .index .news-wrap .news-inner dl dd {
        padding: 14px 0;
    }
    .index .news-wrap .news-inner dl .new-item {
        height: 95px;
    }
    .index .news-wrap .news-inner dl .new-item ul {
        width: 80px;
        margin-right: 10px;
        padding: 6px 10px;
    }
    .index .news-wrap .news-inner dl .new-item ul li:nth-child(1) {
        font-size: 20px;
        line-height: 20px;
    }
    .index .news-wrap .news-inner dl .new-item ul li:nth-child(2) {
        font-size: 36px;
        line-height: 36px;
        height: 36px;
    }
    .index .news-wrap .news-inner dl .new-item ul li:nth-child(3) {
        font-size: 20px;
        line-height: 20px;
    }
    .index .news-wrap .news-inner dl .new-item a h4 {
        line-height: 24px;
    }
    .index .news-wrap .news-inner dl .new-item a p {
        font-size: 12px;
        line-height: 20px;
    }
    .index .a-form-wrap .a-form-inner {
        padding: 10px 15px;
        width: 94%;
    }
    .index .a-form-wrap .a-form-inner .a-form-prompt {
        line-height: 24px;
        font-size: 14px;
        padding-bottom: 6px;
    }
    .index .a-form-wrap .a-form-inner .a-form-prompt p {
        margin: 6px 0;
    }
    .index .a-page-header .a-page-header-inner h1 {
        font-size: 30px;
        margin-bottom: 15px;
        margin-top: 64px;
    }
    .index .a-page-header .a-page-header-inner p {
        font-size: 16px;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item {
        margin: 20px 0;
        padding: 6px 0;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item h3 {
        font-size: 16px;
        padding-bottom: 4px;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item h3 div {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        width: 50px;
        border-radius: 3px;
        padding: 0 6px;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item p {
        font-size: 12px;
        padding-bottom: 4px;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item input,
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item textarea {
        padding: 8px;
        font-size: 14px;
    }
    .index .a-form-wrap .a-form-inner .a-form-content .form-row .form-item .error-message {
        left: 0px;
        font-size: 12px;
    }
    .index .a-form-wrap .a-form-inner .contact_us_submit {
        width: 180px;
        height: 35px;
        line-height: 35px;
        margin: 15px auto 30px auto;
    }
    .index .game-wrap {
        padding: 20px 0;
    }
    .index .game-wrap .game-inner {
        margin: 0 15px 0 15px;
        padding: 20px 15px 20px 15px;
        width: auto;
    }
    .index .game-wrap .game-inner .game-prompt {
        padding-bottom: 15px;
    }
    .index .game-wrap .game-inner .game-prompt h1 {
        line-height: 24px;
    }
    .index .game-wrap .game-inner .game-prompt p {
        line-height: 20px;
    }
    .index .product-wrap {
        padding: 15px 0 2px 0;
    }
    .index .product-wrap .product-inner>ul li {
        width: 94%;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .index .product-wrap .product-inner>ul .middle {
        margin: 0 auto 20px auto;
    }
    .index .product-wrap .product-inner>ul li .product-info {
        padding: 10px 16px 14px 16px;
    }
    .index .product-wrap .product-inner>ul li .product-info h3 {
        font-size: 20px;
        padding-bottom: 10px;
    }
    .index .product-wrap .product-inner>ul li .product-info p {
        font-size: 16px;
    }
    .index .product-wrap .product-inner>ul li .product-info div:first-child {
        width: calc(100% - 40px);
    }
    .index .product-wrap .product-inner>ul li .product-info .product-more {
        width: 30px;
        height: 30px;
    }
    .index .product-wrap .product-inner>ul li .product-info .product-more span {
        width: 8px;
        height: 8px;
        margin-left: -4px;
    }
    .index .a-page-header .a-page-header-inner .new-detail-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: normal;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 30px;
        font-size: 20px;
    }
    .index .game-wrap .game-inner .new-content {
        padding: 0;
    }
    .index .bread-crumbs ul {
        width: 94%;
        margin: 0 auto 10px auto;
    }
    .index .news-inner .bread-crumbs {
        margin-top: 20px;
    }
    .pc_img_01 {
        position: relative;
        top: -210px;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
    }
    .pc_img_02 {
        margin-left: -300px;
        position: relative;
        top: -100px;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
    }
    .pc_img_03 {
        position: relative;
        top: -180px;
        margin-left: -150px;
        transform: scale(0.65);
        -webkit-transform: scale(0.65);
    }
}